import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.autocomplete');
import { OnInit, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { Service } from "src/libs/season/service";

@Component({
    selector: 'wiz-component-autocomplete',
template: templateSource || '',
    styles: [`

/* file: /var/www/nuch/branch/main/build/src/app/component.autocomplete/view.scss */
.autocomplete {
  height: 34px;
  border: 1px solid #e6e7e9;
  border-radius: 4px;
}
.autocomplete .autocomplete-value {
  border: 0;
  box-shadow: none;
  padding-right: 30px;
}
.autocomplete .autocomplete-value:focus {
  border: 1px solid #c9151e;
  box-shadow: 0 0 0 0.25rem rgba(201, 21, 30, 0.25);
}
.autocomplete .autocomplete-value:focus + .search-list {
  display: block;
  position: absolute;
  top: 32px;
  left: 0;
  right: 0;
  z-index: 999999;
}
.autocomplete .autocomplete-value.show-icon {
  padding-left: 32px;
}
.autocomplete img.icon {
  position: absolute;
  top: 6px;
  left: 6px;
}
.autocomplete .search-list {
  display: none;
  max-height: 400px;
  overflow: auto;
  background-color: white;
  border: 1px solid var(--wiz-color-border);
  border-top: none;
}
.autocomplete .search-list .search-list-item {
  line-height: 32px;
  cursor: pointer;
}
.autocomplete .search-list .search-list-item.current {
  background-color: rgba(201, 21, 30, 0.25);
}
.autocomplete .search-list .search-list-item.focus {
  background-color: rgba(201, 21, 30, 0.15);
}
.autocomplete .search-list .search-list-item:hover {
  background-color: rgba(201, 21, 30, 0.2);
}
.autocomplete .search-list .search-list-item + .search-list-item {
  border-top: 1px solid #e6e7e9;
}
.autocomplete .position-absolute {
  top: 0;
  right: 6px;
}
.autocomplete i.fa-solid.fa-magnifying-glass {
  line-height: 32px;
}`],
})
export class ComponentAutocompleteComponent implements OnInit, OnChanges {
    @Input() item_id: string = '';
    @Input() set list(arr) {
        this.data = arr;
        this.filtered = arr;

        for (let i = 0; i < arr.length; i++) {
            if (arr[i].id == this.item_id) {
                this.item = arr[i];
            }
        }

        this.showIcon = this.item.icon ? true : false;
    };
    @Input() disabled = false;
    @Input() query = null;
    @Input() blur = true;
    @Output() onSelect = new EventEmitter<any>();
    @Input() bindLabel = "name";
    @Input() bindValue = "id";
    @Input() placeholder = "Search";

    public item: string = {};
    public data = [];
    public filtered = this.data;
    public currentFocus = 0;
    public showIcon = false;

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
    }

    public async ngOnChanges(changes) {
        if (!changes.item) return;
        const p = changes.item.previousValue;
        const c = changes.item.currentValue;
        if (!p) {
            this.item = c;
            this.showIcon = this.item.icon ? true : false;
            this.service.render();
            return;
        }
        if (c.id && p.id === c.id) return;
        this.item = c;
        this.showIcon = this.item.icon ? true : false;
        this.service.render();
    }

    private key = {
        up: (e) => {
            e.preventDefault();
            if (this.currentFocus - 1 < 0) {
                this.currentFocus = 0;
                return;
            }
            this.currentFocus -= 1;
        },
        down: (e) => {
            e.preventDefault();
            const len = this.filtered.length;
            if (this.currentFocus + 1 >= len) {
                this.currentFocus = 0;
                return;
            }
            this.currentFocus += 1;
        },
        enter: (e) => {
            e.preventDefault();
            try {
                const item = this.filtered[this.currentFocus];
                this.item = { ...item };
                this.showIcon = item.icon ? true : false;
                this.service.render();
                if (this.blur) {
                    document.activeElement.blur();
                }
                else if (this.query) {
                    this.data = [];
                    this.filtered = [];
                    this.currentFocus = 0;
                    this.service.render();
                }
                const target = Object.assign({}, item);
                this.onSelect.emit({
                    e,
                    item: target
                });
            } catch { }
        },
        esc: (e) => {
            e.preventDefault();
            document.activeElement.blur();
        },
    }

    private async _onChange(e) {
        try {
            const target = this.item[this.bindLabel].toLowerCase();
            if (this.query) {
                this.data = await this.query(target);
                this.filtered = this.data;
                this.service.render();
                return;
            }
            if (target.length === 0) {
                this.filtered = this.data;
                this.service.render();
                return;
            }
            this.filtered = this.data.filter(it => it[this.bindLabel].toLowerCase().includes(target));
            this.service.render();
        } catch (e) {
        }
    }

    private _onBlur(e) {
        const arr = this.data.map(it => it[this.bindLabel]);
        if (arr.includes(this.item[this.bindLabel])) return;
        this.currentFocus = 0;
        this.item = {};
        this.filtered = this.data;
        this.service.render();
    }

    private _onFocus(e) {
        if (this.query) {
            this.data = [];
            this.filtered = [];
            this.currentFocus = 0;
            this.service.render();
            return;
        }
        const i = this.filtered.findIndex(it => it[this.bindValue] === this.item[this.bindValue]);
        if (i < 0) this.currentFocus = 0;
        else this.currentFocus = i;
        this.service.render();
    }

    private _onSelect(e, i) {
        this.currentFocus = i;
        this.key.enter(e);
        const target = Object.assign({}, this.filtered[i]);
        try {
            this.onSelect.emit({
                e,
                item: target,
            });
        } catch (e) { }
    }

    private _onMouseDown(e) {
        e.preventDefault();
    }
}

export default ComponentAutocompleteComponent;