import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.admin.template');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/season/service";
import toastr from "toastr";

type Mode = "edit" | "new";
interface Class {
    id?: string,
    name: string,
    abbr: string,
    attributes: string,
    icon?: string,
}

toastr.options = {
    "closeButton": false,
    "debug": false,
    "newestOnTop": false,
    "progressBar": false,
    "positionClass": "toast-bottom-right",
    "preventDuplicates": false,
    "onclick": null,
    "showDuration": "300",
    "hideDuration": "1000",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut",
};

@Component({
    selector: 'wiz-page-admin-template',
template: templateSource || '',
    styles: [`

/* file: /var/www/nuch/branch/main/build/src/app/page.admin.template/view.scss */
.searchbar {
  height: 34px;
  border: 1px solid #e6e7e9;
  border-radius: 4px;
}
.searchbar input[type=text] {
  border: 0;
  box-shadow: none;
  padding-right: 30px;
}
.searchbar input[type=text]:focus {
  border: 1px solid #c9151e;
  box-shadow: 0 0 0 0.25rem rgba(201, 21, 30, 0.25);
}
.searchbar .position-absolute {
  top: 0;
  right: 6px;
}
.searchbar i.fa-solid.fa-magnifying-glass {
  line-height: 32px;
}

table tr.active > td {
  background-color: #ececec;
}
table td {
  line-height: 32px;
}
table td i.fa-solid {
  font-size: 20px;
  cursor: pointer;
}
table img {
  display: inline-block;
}

.workspace {
  width: 100%;
  height: 100%;
  display: flex;
}

.list-area {
  width: 50%;
  max-width: 480px;
  height: 100%;
  padding: 12px;
}

.editor-area {
  flex: auto;
  height: 100%;
  padding: 12px;
}`],
})
export class PageAdminTemplateComponent implements OnInit {
    private text: string = "";
    private list: Array = [];
    private currentId = null;
    private current = {};
    private mode: Mode = "edit";
    private tmpDisabled = false;

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', '/auth/login');
        await this.onLoad();
    }

    private copy(o) {
        return JSON.parse(JSON.stringify(o));
    }

    private async onLoad() {
        const { code, data } = await wiz.call("load");
        if (code !== 200) {
            toastr.error("Failed load");
            return;
        }
        this.list = data;
        this.onEdit(this.list[0]);
        await this.service.render();
    }

    private filter(item) {
        const { name, abbr } = item;
        const text = this.text.toLowerCase();
        return name.toLowerCase().includes(this.text) || abbr.toLowerCase().includes(this.text);
    }

    private displayAttrs(attrs) {
        return attrs.map(it => it.displayName).join(",")
    }

    private emptyClass() {
        return {
            name: "",
            abbr: "",
            attributes: [],
            icon: null,
        }
    }

    private emptyAttr() {
        return {
            attribute: "",
            displayName: "",
            type: "text",
            placeholder: "",
        }
    }

    private async setMode(mode: Mode) {
        this.mode = mode;
        switch (mode) {
            case "edit":
                const obj = this.list.filter(it => it.id === this.currentId)[0];
                this.current = this.copy(obj);
                if (this.current.icon) this.tmpDisabled = true;
                else this.tmpDisabled = false;
                break;
            case "new":
                this.current = this.emptyClass();
                this.currentId = null;
                this.tmpDisabled = false;
                break;
        }
        await this.service.render();
    }

    private onEdit(item) {
        this.currentId = item.id;
        this.setMode("edit");
    }

    private setClassIcon(e) {
        if (e.target.files.length === 0) return;
        const file = e.target.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            this.current.icon = reader.result;
            this.service.render();
        };
        reader.onerror = (err) => { console.debug(err); };
    }

    private removeIcon() {
        this.current.icon = null;
        this.service.render();
        setTimeout(() => {
            this.tmpDisabled = false;
            this.service.render();
        }, 100);
    }

    private removeAttr(i) {
        this.current.attributes = [
            ...this.current.attributes.slice(0, i),
            ...this.current.attributes.slice(i + 1),
        ];
        this.service.render();
    }

    private currentIndex() {
        return this.list.findIndex(item => item.id === this.currentId);
    }

    private async onSave() {
        const arr = [];
        for (let i = 0; i < this.current.attributes.length; i++) {
            const it = this.current.attributes[i];
            if (!arr.includes(it.attribute)) {
                arr.push(it.attribute);
            }
            else {
                this.service.alert.show({
                    title: "Validation Error",
                    message: "Attribute에 중복된 값이 있습니다.",
                    action: "닫기",
                    cancel: false,
                });
                return;
            }
        }
        const body: Class = {
            ...this.current,
            attributes: JSON.stringify(this.current.attributes),
        };
        const { code, data } = await wiz.call("save", body);
        if (code !== 200) {
            toastr.error(data);
            return;
        }
        toastr.success("Success");
        await this.onLoad();
        this.setMode("edit");
    }

    private async onRemove() {
        const res = await this.service.alert.show({ message: "정말 삭제하시겠습니까?" });
        if (!res) return;

        await wiz.call("remove", { id: this.currentId });
        await this.onLoad();
        this.setMode("edit");
    }
}

export default PageAdminTemplateComponent;