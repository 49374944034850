import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.test');
import { AfterViewInit, ElementRef, ViewChild, OnInit, Input } from '@angular/core';

@Component({
    selector: 'wiz-page-test',
template: templateSource || '',
    styles: [`

/* file: /var/www/nuch/branch/main/build/src/app/page.test/view.scss */
/* src/app/page.test/view.scss: no such file or directory */`],
})
export class PageTestComponent implements OnInit {

    public async ngOnInit() {
    }
}

export default PageTestComponent;