import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.test2');
import { OnInit, Input } from '@angular/core';
import * as qs from 'qs';
@Component({
    selector: 'wiz-page-test2',
template: templateSource || '',
    styles: [`

/* file: /var/www/nuch/branch/main/build/src/app/page.test2/view.scss */
/* src/app/page.test2/view.scss: no such file or directory */`],
})
export class PageTest2Component implements OnInit {

    public filename: any = '';
    public async ngOnInit() {
        console.log(window.location.href);

        const queryStr = window.location.search.substring(1);
        this.filename = qs.parse(queryStr);
        console.log('filename', this.filename.vr);
    }
}

export default PageTest2Component;