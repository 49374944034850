import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.image');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/season/service";

@Component({
    selector: 'wiz-component-image',
template: templateSource || '',
    styles: [`

/* file: /var/www/nuch/branch/main/build/src/app/component.image/view.scss */
.card-gray {
  position: relative;
  background-color: #F2F2F3;
  border: none;
  border-radius: 0;
  padding: 10px 20px;
}
.card-gray.mode-text {
  padding: 0;
}
.card-gray.mode-text:hover {
  background-color: #ffb2b2;
}
.card-gray.mode-text.fullscreen img {
  max-height: 100%;
}
.card-gray.mode-text.fullscreen:hover {
  background-color: #F2F2F3;
}
.card-gray:not(.fullscreen):not(.mode-text) {
  width: 100%;
  height: 100%;
}
.card-gray:not(.fullscreen) {
  display: inline-block;
  width: fit-content;
  padding: 0;
}
.card-gray.fullscreen {
  position: fixed;
  padding: 0;
  inset: 30px 40px;
  z-index: 99999;
  overflow: auto;
}
.card-gray.fullscreen img {
  max-width: none;
  width: fit-content;
  height: 100%;
  margin: auto;
}
.card-gray.fullscreen .btn-close {
  position: absolute;
  top: 10px;
  right: 10px;
  border-color: black;
  background-color: white;
  width: 15px;
  height: 15px;
  background-size: 15px;
  opacity: 1;
}

.image-area {
  width: 100%;
  height: 100%;
  display: block;
  padding: 0;
  background: no-repeat center/cover;
}

.btn-images {
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 24px;
  height: 24px;
  background: transparent url("/assets/images/main_button_1.svg") 0% 0% no-repeat padding-box;
  opacity: 1;
  border: none;
}`],
})
export class ComponentImageComponent implements OnInit {
    @Input() src: string;
    @Input() mode: string = "default";
    @Input() landscape: boolean = false;

    private fullscreen: boolean = false;
    private flag = true;
    private coverStyle: any;

    constructor(@Inject( Service)         public service: Service,    ) {
        service.init();
    }

    public async ngOnInit() {
        this.coverStyle = {
            position: "relative",
            'background-image': "url('" + this.src + "')"
        };
    }

    private async toggle(e) {
        this.flag = !this.flag;
        await this.service.render();

        const img = e.currentTarget.querySelector("img");
        if (!img) return;
        if (this.flag) {
            if (!this.fullscreen) return;
            const { naturalWidth, naturalHeight } = img;
            img.style.width = `${naturalWidth}px`;
            img.style.height = `${naturalHeight}px`;
            img.style.maxWidth = "100%";
            img.style.maxHeight = "none";
        }
        else {
            img.style.width = ``;
            img.style.height = ``;
            img.style.maxWidth = "none";
            img.style.maxHeight = "100%";
        }

    }

    private async setFullscreen(act) {
        await this.service.render();
        this.fullscreen = act;
        const img = document.querySelector("#img-component");
        // img.style.maxHeight = act ? "none" : "100%";
        this.service.render();
    }
}

export default ComponentImageComponent;