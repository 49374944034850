import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.loading');
import { OnInit, Input } from '@angular/core';

@Component({
    selector: 'wiz-component-loading',
template: templateSource || '',
    styles: [`

/* file: /var/www/nuch/branch/main/build/src/app/component.loading/view.scss */
.socket {
  width: 50px;
  border-radius: 50px;
  height: 50px;
  position: absolute;
  left: 55%;
  margin-left: -100px;
  top: 55%;
  margin-top: -100px;
}
.socket .loader-38 {
  width: 48px;
  height: 48px;
  display: inline-block;
  position: relative;
  color: #263238;
  animation: rotation 1s linear infinite;
}
.socket .loader-38:after, .socket .loader-38:before {
  content: "";
  position: absolute;
  width: 24px;
  height: 24px;
  top: 50%;
  left: 50%;
  transform: scale(0.5) translate(0, 0);
  background-color: darkred;
  border-radius: 50%;
  animation: animloader38 1s infinite ease-in-out;
}
.socket .loader-38:before {
  background-color: red;
  transform: scale(0.5) translate(-48px, -48px);
}

@keyframes animloader38 {
  50% {
    transform: scale(1) translate(-50%, -50%);
  }
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}`],
})
export class ComponentLoadingComponent implements OnInit {
    public async ngOnInit() {
    }
}

export default ComponentLoadingComponent;