import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.doc.editor');
import { Component, ElementRef, ViewChild, Input, OnInit } from '@angular/core';
import { Service } from "src/libs/season/service";
import BuildedEditor from '@ckeditor/ckeditor5-build-decoupled-document';

@Component({
    selector: 'wiz-component-doc-editor',
template: templateSource || '',
    styles: [`

/* file: /var/www/nuch/branch/main/build/src/app/component.doc.editor/view.scss */
.editor {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.editor-wrap {
  flex: 1;
  overflow: auto;
}

.editor-area {
  height: 100%;
  border: none !important;
  box-shadow: none !important;
}`],
})
export class ComponentDocEditorComponent implements OnInit {
    @Input() data: any = {};

    public editorInstance: any;

    @ViewChild('toolbar')
    public toolbar: ElementRef;

    @ViewChild('editor')
    public editor: ElementRef;

    constructor(@Inject( Service) public service: Service) {
    }

    public async ngOnInit() {
        await this.service.init();

        this.editorInstance = await BuildedEditor.create(this.editor.nativeElement, {
            toolbar: [
                "heading",
                "|", "undo", "redo",
                "|", "fontColor", "fontBackgroundColor",
                "|", "bold", "italic", "underline", "strikethrough",
                "|", "alignment",
                "|", "numberedList", "bulletedList",
                "|", "outdent", "indent",
                "|", "link", "blockquote", "uploadImage", "insertTable"
            ]
        });

        this.toolbar.nativeElement.appendChild(this.editorInstance.ui.view.toolbar.element);
        this.editorInstance.setData(this.data.description);

        this.editorInstance.model.document.on('change:data', async (evt, data) => {
            this.data.description = this.editorInstance.getData();
            await this.service.render();
        });
    }
}

export default ComponentDocEditorComponent;