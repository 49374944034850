import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('layout.navbar');
import { OnInit, ChangeDetectorRef } from '@angular/core';
import { Router } from '@angular/router';
import { Service } from "src/libs/season/service";
@Component({
    selector: 'wiz-layout-navbar',
template: templateSource || '',
    styles: [`

/* file: /var/www/nuch/branch/main/build/src/app/layout.navbar/view.scss */
.wiz-nav-page {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.wiz-nav-page > .wiz-view {
  flex: auto;
  overflow: auto;
  background-color: var(--wiz-color-light-1);
}
.wiz-nav-page > .wiz-view > * {
  flex: auto;
}
.wiz-nav-page > .wiz-view .wiz-footer {
  flex: none;
  color: #888;
  background: #3a3a3a;
  font-size: 16px;
  padding-top: 32px;
  padding-bottom: 32px;
}
.wiz-nav-page > .wiz-view .wiz-footer small {
  font-size: 14px;
}
.wiz-nav-page > .wiz-view .wiz-footer a {
  color: #888;
}
.wiz-nav-page > .wiz-view .wiz-footer a.color {
  color: var(--wiz-color-red);
  cursor: pointer;
}

.block-page {
  background-color: rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10000;
}`],
})
export class LayoutNavbarComponent implements OnInit {
    constructor(@Inject( Service)         public service: Service,@Inject( ChangeDetectorRef)         public ref: ChangeDetectorRef,@Inject( Router)         public router: Router,    ) { }

    public async ngOnInit() {
        this.service.init(this);
    }
}

export default LayoutNavbarComponent;