import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.wiki');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/season/service";

@Component({
    selector: 'wiz-component-wiki',
template: templateSource || '',
    styles: [`

/* file: /var/www/nuch/branch/main/build/src/app/component.wiki/view.scss */
.wiki-modal {
  position: absolute;
  inset: 0;
  padding: 20px;
  margin: 0;
  border-radius: 10px;
}
.wiki-modal > .card {
  padding: 15px;
}
.wiki-modal .close {
  top: 10px;
  right: 10px;
  width: auto;
  height: auto;
  padding: 0;
  margin: 0;
}
.wiki-modal .close .btn.btn-close {
  width: 40px;
  height: 40px;
  background-size: 25px;
}
.wiki-modal .title {
  font-family: "wiz-eb";
  font-weight: bold;
}
.wiki-modal .img-wrap {
  max-height: 400px;
  width: fit-content;
  margin: 0 auto;
}
.wiki-modal .img-list-wrap > .col-md-4 {
  width: fit-content;
  margin: 0 auto;
  padding: 10px;
  cursor: pointer;
  border: 1px solid transparent;
}
.wiki-modal .img-list-wrap > .col-md-4.current {
  border: 1px solid rgba(201, 21, 30, 0.5);
}
.wiki-modal .img-list-wrap > .col-md-4:hover {
  border: 1px solid #c9151e;
}
.wiki-modal .img-list-wrap > .col-md-4 img {
  height: 100px;
}
.wiki-modal .top {
  border-top: 1px black solid;
}
.wiki-modal .bottom {
  border-bottom: 1px black solid;
}
.wiki-modal th {
  width: fit-content;
  background-color: #F2F2F3;
  border-right: 1px black solid;
}
.wiki-modal .tdata {
  max-width: 700px;
}`],
})
export class ComponentWikiComponent implements OnInit {
    private data = {};
    // private imgs = [null, null, null];
    private imgs = [null];
    private loading = true;
    private imgCurrent = 0;

    constructor(@Inject( Service)         public service: Service,    ) {
        service.init();
    }

    public async ngOnInit() {
        const id = this.service.wiki;
        const { code, data } = await wiz.call("load", { id });
        this.data = data;
        this.imgs[0] = data.img;
        this.imgCurrent = 0;
        this.loading = false;
        this.service.render();
    }

    private onClickImg (i) {
        this.imgCurrent = i;
        this.service.render();
    }

}

export default ComponentWikiComponent;