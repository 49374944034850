import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.vr.viewer');
import { Component, Input, OnInit, OnDestroy } from '@angular/core';
import { Service } from "src/libs/season/service";
import { DomSanitizer } from '@angular/platform-browser';
import videojs from "video.js";
require('videojs-vr');

@Component({
    selector: 'wiz-component-vr-viewer',
template: templateSource || '',
    styles: [`

/* file: /var/www/nuch/branch/main/build/src/app/component.vr.viewer/view.scss */
.video-wrapper {
  width: 100%;
  height: 100%;
  overflow: auto;
}

.video-js {
  width: 100%;
}

video {
  width: 100%;
  height: 360px !important;
}`],
})
export class ComponentVrViewerComponent implements OnInit, OnDestroy {
    @Input() src: string = '';

    public player = null;
    public fullscreen: boolean = false;
    public flag = true;

    private basePath = '/res/vr/';

    constructor(@Inject( DomSanitizer)         public sanitizer: DomSanitizer,@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
        console.log(this.src);
        // this.src = `${this.basePath}${this.src}?time=${new Date().getTime()}`;
        this.src = `${this.basePath}${this.src}`;
        console.log("src", this.src);
        await this.service.render();
        this.player = videojs("video");

        const bigPlayButton = document.querySelector('.vjs-big-play-button');
        if (bigPlayButton) {
            bigPlayButton.classList.remove('vjs-big-vr-play-button');
        }
        this.player.mediainfo = this.player.mediainfo || {};
        this.player.mediainfo.projection = '360';
        this.player.vr({ projection: 'AUTO', debug: false, forceCardboard: false });
    }

    public async ngOnDestroy() {
        this.player.dispose();
    } 
}

export default ComponentVrViewerComponent;