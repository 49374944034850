import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.detail');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/season/service";
import { DataSet } from "vis-data";

@Component({
    selector: 'wiz-component-detail',
template: templateSource || '',
    styles: [`

/* file: /var/www/nuch/branch/main/build/src/app/component.detail/view.scss */
.wiz-modal {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  padding: 12px;
}
.wiz-modal .modal {
  display: contents;
  width: 100%;
  max-width: 420px;
}
.wiz-modal .modal-contents {
  position: relative;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  background-color: none;
  width: 100%;
  z-index: 5000;
  outline: 0;
}
.wiz-modal .modal-contents .card {
  background-color: #d8dbdc;
  max-width: 300px;
}
.wiz-modal .modal-contents .card img {
  max-width: 250px;
  max-height: 200px;
  object-fit: contain;
  background-color: white;
}
.wiz-modal .modal-contents .card .summary {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}`],
})
export class ComponentDetailComponent implements OnInit {
    @Input() x: any;
    @Input() y: any;
    @Input() data: any;
    @Input() nodes: any;
    @Input() edges: any;
    @Input() network: any;

    private detailShow: boolean = false;

    public hasNewNodes: boolean = true;

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        const id = this.data['id'];
        const { code, data } = await wiz.call("expand", { id });
        let { nodes, edges } = data;
        const existingNodeIds = new Set(this.nodes.map(node => node.id));
        const newNodes = nodes.filter(node => !existingNodeIds.has(node.id));
        console.log(newNodes);
        this.hasNewNodes = newNodes.length > 0;
        await this.service.render();
    }

    public async detailInfo() {
        this.detailShow = !this.detailShow;
        await this.service.render();
    }

    public async relInfo() {
        this.detailShow = false;
        await this.expandNode(this.data['id']);
        await this.service.render();
    }


    public async expandNode(id: string) {
        const { code, data } = await wiz.call("expand", { id });
        let { nodes, edges } = data;

        const existingNodeIds = new Set(this.nodes.map(node => node.id));
        nodes = nodes.filter(node => !existingNodeIds.has(node.id));

        const existingEdgeIds = new Set(this.edges.map(edge => `${edge.from}-${edge.to}`));
        edges = edges.filter(edge => !existingEdgeIds.has(`${edge.from}-${edge.to}`));

        this.nodes = [...this.nodes, ...nodes];
        this.edges = [...this.edges, ...edges];

        const networkNodeIds = new Set(this.network.body.data.nodes.getIds());
        nodes.forEach(node => {
            if (!networkNodeIds.has(node.id)) {
                this.network.body.data.nodes.add(node);
            }
        });

        const networkEdgeIds = new Set(this.network.body.data.edges.map(edge => `${edge.from}-${edge.to}`));
        edges.forEach(edge => {
            if (!networkEdgeIds.has(`${edge.from}-${edge.to}`)) {
                this.network.body.data.edges.add(edge);
            }
        });

        await this.service.render();
    }
}

export default ComponentDetailComponent;