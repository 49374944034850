import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.main');
import { OnInit } from "@angular/core";
import { Service } from "src/libs/season/service";

@Component({
    selector: 'wiz-page-main',
template: templateSource || '',
    styles: [`

/* file: /var/www/nuch/branch/main/build/src/app/page.main/view.scss */
.workspace {
  width: 100%;
  height: 100%;
  display: flex;
}

.info-area {
  display: flex;
  flex-direction: column;
  width: 17%;
  height: 100%;
  padding: 16px;
}
.info-area > div {
  margin-bottom: 16px;
}
.info-area > div:last-child {
  margin-bottom: 0;
}
.info-area .info-card img {
  margin-top: 8px;
  margin-bottom: 16px;
}
.info-area .featured-card {
  flex: auto;
}
.info-area .featured-card .card-body {
  height: 0px;
  overflow: hidden;
}

.content-area {
  flex: auto;
  height: 100%;
  width: 83%;
  padding: 16px;
  padding-left: 0;
}
.content-area .card {
  display: block;
  height: 100%;
}
.content-area .card .action-area {
  position: relative;
  padding: 12px;
  text-align: center;
}
.content-area .card .action-area .btn {
  min-width: 120px;
}
.content-area .card .action-area .admin-menu {
  position: absolute;
  top: 16px;
  right: 24px;
}
.content-area .card .action-area .admin-menu .text-muted {
  cursor: pointer;
}
.content-area .card .action-area .admin-menu .text-muted:hover {
  color: var(--wiz-color-red) !important;
}
.content-area .card .card-body {
  height: calc(100% - 72px);
  overflow: auto;
}
.content-area .card .card-end {
  height: 16px;
}

.h-100 {
  height: 100%;
}

.card {
  border-radius: 15px;
}

.content-description {
  max-width: 720px;
}

@media (max-width: 768px) {
  .workspace {
    display: block;
  }
  .workspace > div {
    width: 100%;
  }
  .info-area {
    height: auto;
    overflow: hidden;
  }
  .info-area .featured-card {
    flex: auto;
  }
  .info-area .featured-card .card-body {
    height: auto;
    overflow: hidden;
  }
  .admin-menu {
    display: none;
  }
  .content-area {
    padding-left: 16px;
  }
}`],
})
export class PageMainComponent implements OnInit {

    public mode: string = "graph"; // graph, doc
    public info: any = { obj3d: [] };

    constructor(@Inject( Service) public service: Service) { }

    public async ngOnInit() {
        await this.service.init();
        await this.load();
    }

    public get3DUri() {
        let links = [];
        for (let i = 0; i < this.info.obj3d.length; i++)
            links.push("/res/3d/" + this.info.id + "/" + this.info.obj3d[i]);
        return links;
    }

    public async load() {
        let { data } = await wiz.call("info")
        this.info = data;
        console.log(this.info);
        await this.service.render();
    }

    public isActive(menu: string) {
        return this.mode == menu ? 'btn-red' : 'btn-outline-red';
    }

    public async active(menu: string) {
        this.mode = menu;
        await this.service.render();
    }

}

export default PageMainComponent;