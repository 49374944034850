import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.3d.viewer');
import { Component, ElementRef, Renderer2, ViewChild, Input, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Service } from "src/libs/season/service";
import { Website } from "src/libs/3D/website.js";
import toastr from 'toastr';

toastr.options = {
    "closeButton": false,
    "debug": false,
    "newestOnTop": false,
    "progressBar": false,
    "positionClass": "toast-bottom-center",
    "preventDuplicates": false,
    "onclick": null,
    "showDuration": "3000",
    "hideDuration": "1000",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut",
};

@Component({
    selector: 'wiz-component-3d-viewer',
template: templateSource || '',
    styles: [`

/* file: /var/www/nuch/branch/main/build/src/app/component.3d.viewer/view.scss */
.workspace {
  width: 100%;
  height: 100%;
  display: flex;
}

canvas {
  border: none !important;
}

.info-area {
  overflow: auto;
  width: 400px;
  height: 100%;
}
.info-area .info-card img {
  margin-top: 8px;
  margin-bottom: 16px;
}
.info-area .info-card .text-muted {
  max-height: 300px;
  overflow: auto;
}
.info-area .info-card .featured-image {
  position: relative;
}
.info-area .info-card .featured-image .featured-action {
  position: absolute;
  top: 16px;
  right: 8px;
}
.info-area .info-card .featured-image .featured-action .btn {
  width: 24px;
  height: 24px;
}

.featured-card {
  flex: auto;
}

.content-area {
  flex: 1;
  height: 100%;
  width: 85%;
  padding: 16px;
  padding-left: 0;
}
.content-area .card {
  display: block;
  height: 100%;
}
.content-area .card .action-area {
  position: relative;
  padding: 12px;
  text-align: center;
}
.content-area .card .action-area .btn {
  min-width: 120px;
}
.content-area .card .action-area .admin-menu {
  position: absolute;
  top: 16px;
  right: 24px;
}
.content-area .card .action-area .admin-menu .text-muted {
  cursor: pointer;
}
.content-area .card .action-area .admin-menu .text-muted:hover {
  color: var(--wiz-color-red) !important;
}
.content-area .card .card-body {
  height: calc(100% - 56px);
  overflow: auto;
}

.h-100 {
  height: 100%;
}

.card {
  border-radius: 15px;
}

.container.ready {
  max-width: 640px;
}

.container-tight {
  max-width: 580px;
}

.empty-header {
  font-size: 48px;
}

textarea.form-control {
  height: auto !important;
}

.action-btns {
  position: fixed;
  bottom: 32px;
  right: 32px;
}
.action-btns .btn {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  font-size: 24px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.content-info {
  overflow: hidden;
  max-width: 960px;
  margin: 0 auto;
  height: 100%;
}

.content-3d {
  max-width: 960px;
  margin: 0 auto;
}

.content-vr {
  max-width: 960px;
  margin: 0 auto;
}

table {
  width: 100%;
  table-layout: fixed;
}

th {
  background-color: rgba(0, 0, 0, 0.05) !important;
}
th.thead {
  width: 150px;
  border-right: 1px var(--wiz-color-red) solid;
}

.table-actions .btn {
  width: 32px;
}

.float-none {
  float: none !important;
}

.main#main {
  display: block !important;
}

.main > * {
  display: inline-block;
  vertical-align: top;
}

.main_left_container {
  width: 300px !important;
}

.main_viewer {
  width: calc(100% - 400px - 300px);
}

.input-wrap {
  display: flex;
}
.input-wrap label {
  width: 24px;
}
.input-wrap input[type=range] {
  flex: 1;
}
.input-wrap input[type=number] {
  width: 75px;
}

.img-red-dot {
  position: absolute;
  width: 20px;
  height: 20px;
  background-color: red;
  border-radius: 50%;
  transform: translate(-10px, 0px);
}`],
})
export class Component3dViewerComponent implements OnInit, OnDestroy {
    @Input() src: any = [];
    @ViewChild('viewer3d')
    public viewer: ElementRef;
    public website: Website;
    private show = {
        obj: false
    }
    public objName: string = "";
    public obj: any = {};
    public info: any = {};
    public meta: any = {};
    public mode: string = "doc";
    public link: string = "from";
    public relSearchInput: boolean = true;
    public target: string = "";
    public templates: any = [];
    public templatemap: any = {};
    public rel: any = { 'from': [], 'to': [] };
    public targetNode: string = "";
    public selectedItem: any = { 'name': "", 'x': 0, 'y': 0 }
    public pointPosition: { top: number, left: number } = { top: 0, left: 0 };
    public dotMax = 100;

    constructor(@Inject( Service) public service: Service,@Inject( Renderer2)  private renderer: Renderer2) {
        this.objName = decodeURIComponent(WizRoute.segment.query);
    }

    public async ngOnInit() {
        await this.service.init();
        await this.service.render();
        let viewer = this.viewer.nativeElement;
        this.website = new Website(viewer);
        await this.website.Load();
        if (this.src.length > 0)
            await this.website.LoadModelFromUrlList(this.src);

        await this.nameReplace();
        await this.toggleUpload();
        await this.leftTab();
        await this.loadImage();
    }

    public async loadImage() {
        const { code, data } = await wiz.call("info", { target: this.objName });
        let templates = data.templates;

        let templatemap = {};
        for (let i = 0; i < templates.length; i++) {
            templatemap[templates[i].id] = templates[i];
        }

        let meta = {};
        let rel = { 'from': [], 'to': [] };
        if (code == 200) {
            this.obj = data.info;

            this.obj.status = 200;

            for (let i = 0; i < data.meta.length; i++) {
                meta[data.meta[i].namespace] = data.meta[i].value;
            }

            rel['from'] = data.rel['from'];
            rel['to'] = data.rel['to'];
        }
        await this.service.render();
    }

    public async nameReplace() {
        let titles = [];
        const _intervalId = setInterval(async () => {
            const items = document.querySelectorAll("div.ov_tree_item.clickable");
            if (items.length > 0) {
                clearInterval(_intervalId);
            }
            items.forEach((item) => {
                titles.push({ title: item.getAttribute('title'), element: item });
            })
            if (titles.length > 0) {
                const titleArray = titles.map(t => t.title);
                const { code, data } = await wiz.call("replaceName", {
                    titles: JSON.stringify(titleArray)
                });

                const filteredData = data.filter((item) => item.length > 0);
                filteredData.forEach((data) => {
                    titles.forEach(async (titleObj) => {
                        if (data[0]['name'] === titleObj.title) {
                            titleObj.element.querySelector('.ov_tree_item_name').innerText = data[0]['change'];
                            await this.service.render();
                        }
                    });
                });
            }
        }, 300);
    }

    public async leftTab() {
        // ktw 수정; 참고 바람
        this.renderer.listen(window.main_left_container.querySelector('#main_navigator'), 'click', async (event) => {
            this.targetNode = document.querySelector('.ov_tree_item.clickable.selected');
            this.show.obj = false;
            await this.service.render();
            if (this.targetNode) {
                this.show.obj = !this.show.obj;
                const title = this.targetNode.getAttribute('title');
                const { code, data } = await wiz.call("mapSearch", { text: title });
                if (code === 200 && data.length > 0) {
                    this.selectedItem['name'] = data[0].change;
                    this.selectedItem['x'] = data[0].x;
                    this.selectedItem['y'] = data[0].y;
                    await this.onPositionChange();
                    await this.load(this.selectedItem['name']);
                }
                else {
                    this.selectedItem['name'] = '';
                    this.selectedItem['x'] = 20;
                    this.selectedItem['y'] = 10;
                    await this.onPositionChange();
                    await this.load(this.selectedItem['name']);
                }
                await this.service.render();
                const elmt = document.querySelector(".info-area.float-none .featured-image > img");
                console.log(elmt);
                if (!elmt) return;
                const tid = setInterval(() => {
                    try {
                        const { clientWidth, clientHeight } = elmt;
                        this.dotMax = clientHeight;
                        clearInterval(tid);
                    } catch { }
                }, 200);
                console.dir(elmt);
            }
        })
        await this.service.render();
    }

    public async toggleUpload() {
        this.renderer.listen(this.viewer.nativeElement.querySelector('canvas'), 'click', async (event) => {
            this.targetNode = document.querySelector('.ov_tree_item.clickable.selected');
            this.show.obj = false;
            await this.service.render();
            if (this.targetNode) {
                this.show.obj = !this.show.obj;
                const title = this.targetNode.getAttribute('title');
                const { code, data } = await wiz.call("mapSearch", { text: title });
                if (code === 200 && data.length > 0) {
                    this.selectedItem['name'] = data[0].change;
                    this.selectedItem['x'] = data[0].x;
                    this.selectedItem['y'] = data[0].y;
                    await this.onPositionChange();
                    await this.load(this.selectedItem['name']);
                }
                else {
                    this.selectedItem['name'] = '';
                    this.selectedItem['x'] = 20;
                    this.selectedItem['y'] = 10;
                    await this.onPositionChange();
                    await this.load(this.selectedItem['name']);
                }
                await this.service.render();
                const elmt = document.querySelector(".info-area.float-none .featured-image > img");
                console.log(elmt);
                if (!elmt) return;
                const tid = setInterval(() => {
                    try {
                        const { clientWidth, clientHeight } = elmt;
                        this.dotMax = clientHeight;
                        clearInterval(tid);
                    } catch { }
                }, 200);
                console.dir(elmt);
            }
        })
        await this.service.render();
    }

    public async onPositionChange() {
        this.pointPosition.left = this.selectedItem['x'];
        this.pointPosition.top = this.selectedItem['y'];
        await this.service.render();
    }

    public findTemplate() {
        let template = this.templatemap[this.info.template_id];
        if (!template) return { attributes: [] };
        return template;
    }

    public isUrl(str) {
        const regex = /^(((http(s?))\:\/\/)?)([0-9a-zA-Z\-]+\.)+[a-zA-Z]{2,6}(\:[0-9]+)?(\/\S*)?/;
        return regex.test(str);
    }

    public async deleteFile(mode: string, filename: string) {
        await wiz.call('deleteFile', { mode, filename });
        await this.loadImage();
        await this.load();
    }

    public async load() {
        this.target = this.selectedItem['name'];
        const { code, data } = await wiz.call("info", { target: this.target });
        this.templates = data.templates;

        this.templatemap = {};
        for (let i = 0; i < this.templates.length; i++) {
            this.templatemap[this.templates[i].id] = this.templates[i];
        }

        this.meta = {};
        this.rel = { 'from': [], 'to': [] };

        if (code == 200) {
            this.info = data.info;
            this.info.status = 200;

            for (let i = 0; i < data.meta.length; i++) {
                this.meta[data.meta[i].namespace] = data.meta[i].value;
            }

            this.rel['from'] = data.rel['from'];
            this.rel['to'] = data.rel['to'];
        } else {
            this.info = {
                status: 200,
                bookmark: 0,
                class_id: '',
                description: '',
                name: "객체를 등록하세요",
                summary: ''
            };
        }
        await this.service.render();
    }

    public async update() {
        if (!this.info.template_id) {
            toastr.error("템플릿을 지정해주세요");
            return
        }
        else {
            const { code, data } = await wiz.call("update", {
                data: JSON.stringify(this.info),
                meta: JSON.stringify(this.meta),
                rel: JSON.stringify(this.rel)
            });
            toastr.success("저장되었습니다")
            this.info.id = data;
            await this.service.render();
        }
    }
    public async upload(mode: string) {
        let files = await this.service.file.select({ accept: "image/*" });
        let fd = new FormData();
        let filepath = [];
        for (let i = 0; i < files.length; i++) {
            if (!files[i].filepath) files[i].filepath = files[i].name;
            fd.append('file[]', files[i]);
            filepath.push(files[i].filepath);
        }
        fd.append("filepath", JSON.stringify(filepath));
        fd.append("id", this.info.id);
        fd.append("mode", mode);

        let url = wiz.url('upload');
        await this.service.file.upload(url, fd);
        await this.loadImage();
        await this.load();
    }

    public searchQuery() {
        return async (text) => {
            const { code, data } = await wiz.call("search", { text });
            if (code !== 200) {
                return [];
            }
            const targetArray = this.rel[this.link].map(it => it.id);
            return data.filter(it => !targetArray.includes(it.id))
                .filter(it => it.id !== this.currentId);
        }
    }

    public async onSelectObject({ item }) {
        this.relSearchInput = false;
        await this.service.render();

        this.relSearchInput = true;
        await this.service.render();

        this.info.name = item.name;
        this.info.summary = item.summary;

        this.selectedItem['name'] = item.name;
        await this.service.render();
    }

    public async onSave() {
        const origin = this.targetNode.getAttribute('title');
        const change = this.selectedItem['name'];
        const x = this.selectedItem['x'];
        const y = this.selectedItem['y'];

        const { code, data } = await wiz.call("mapping", { origin, change, x, y });
        if (code === 400) {
            toastr.success("업데이트되었습니다.")
        }
        else {
            toastr.success("저장되었습니다")
        }
        await this.load();
    }
}

export default Component3dViewerComponent;