import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.viewer.vis');
import { Component, ElementRef, ViewChild, Input, OnInit, ChangeDetectorRef, } from '@angular/core';
import { Service } from "src/libs/season/service";
import { Network } from "vis-network";
import { DataSet } from "vis-data";
import tinycolor from "tinycolor2";

@Component({
    selector: 'wiz-component-viewer-vis',
template: templateSource || '',
    styles: [`

/* file: /var/www/nuch/branch/main/build/src/app/component.viewer.vis/view.scss */
.vis-network {
  width: 100%;
  height: 100%;
}

.btn {
  font-family: "wiz-eb";
  cursor: pointer !important;
  height: 40px;
}
.btn.btn-temp {
  width: 100%;
}
.btn.btn-temp.hover {
  background: gray;
}

img {
  display: inline;
}

.templates {
  position: absolute;
  top: 40px;
  right: 25px;
  z-index: 5000;
  background: white;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  width: 200px;
}

.wiz-modal {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  padding: 12px;
}
.wiz-modal .modal {
  display: contents;
  width: 100%;
  max-width: 420px;
}
.wiz-modal .modal-contents {
  position: relative;
  display: flex;
  flex-direction: column;
  pointer-events: auto;
  background-color: none;
  width: 100%;
  z-index: 5000;
  outline: 0;
}
.wiz-modal .modal-contents .card {
  background-color: #d8dbdc;
  max-width: 300px;
}
.wiz-modal .modal-contents .card img {
  max-width: 250px;
  max-height: 200px;
  object-fit: contain;
  background-color: white;
}
.wiz-modal .modal-contents .card .summary {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}`],
})
export class ComponentViewerVisComponent implements OnInit {
    @Input() query: string = '';

    @ViewChild('canvas')
    public canvas: ElementRef;
    public show = {
        detail: false,
    };
    public data = { id: "", name: "", img: "", summary: "" };
    public nodes: any = [];
    public edges: any = [];
    public x = 0;
    public y = 0;
    public detailShow: boolean = false;
    public templates: { id: string, name: string, color: string, checked: boolean }[] = [];
    public isParent: boolean = false;
    public _loading: boolean = false;

    constructor(@Inject( Service) public service: Service,@Inject( ChangeDetectorRef)  public ref: ChangeDetectorRef) { }

    public async ngOnInit() {
        await this.service.init();
        await this.loading(true);
        await this.load();
    }

    public async load() {
        let { data } = await wiz.call("load", { query: this.query });
        let { nodes, edges } = data;

        nodes = nodes.map(node => {
            const hoverBackgroundColor = this.getHoverColor(node.color?.background, true);
            const hoverBorderColor = this.getHoverColor(node.color?.background, true);
            return {
                ...node,
                color: {
                    ...node.color,
                    hover: {
                        background: hoverBackgroundColor,
                        border: hoverBorderColor,
                    },
                    highlight: {
                        background: hoverBackgroundColor,
                        border: hoverBorderColor,
                    }
                },
            };
        });

        this.totalNodes = nodes.length;
        let minZoom = 0.5;
        let maxZoom = 1.0;
        let lastZoomPosition = { x: 0, y: 0 };

        this.nodes = nodes;
        this.edges = edges;

        this.nodes.forEach(node => {
            if (!this.templates.find(t => t.id === node.template_id)) {
                this.templates.push({ id: node.template_id, name: node.template_name, image: node.image, color: node.template_color, checked: true });
            }
        });

        nodes = new DataSet(nodes);
        edges = new DataSet(edges);

        const options = {
            nodes: {
                size: 50,
                imagePadding: 20,
                borderWidth: 10
            },
            edges: {
                length: 180,
                font: {
                    align: "top",
                    color: "green"
                },
                smooth: {
                    type: 'dynamic'
                }
            },
            interaction: {
                dragNodes: true,
                hover: true,
                navigationButtons: true,
                zoomView: true,
                zoomSpeed: 0.5
            },
            physics: {
                enabled: true,
                barnesHut: {
                    theta: 0.5,
                    gravitationalConstant: -60000,
                    centralGravity: 0,
                    springLength: 95,
                    springConstant: 0.04,
                    damping: 0.05,
                    avoidOverlap: 0
                },

                maxVelocity: 10,
                minVelocity: 0.1,
                solver: 'barnesHut',
                timestep: 0.5
            }
        };
        this.network = new Network(this.canvas.nativeElement, { nodes, edges }, options);
        this.network.once("afterDrawing", async () => {
            await this.loading(false);
            await this.service.render();
        })

        this.network.on("click", () => {
            this.show.detail = false;
            this.detailShow = false;
            this.service.render();
        })
        this.network.on("selectNode", async (params) => {
            await this.onDetail(params.nodes[0]);
            let node = this.network.body.data.nodes.get(params.nodes[0]);
            this.isParent = node.isChild;
            await this.ref.detectChanges();
            this.x = (params.pointer.DOM.x + 50) + "px";
            this.y = params.pointer.DOM.y + "px";
            this.show.detail = true;
            await this.service.render();
        });

        this.network.on("zoom", () => {
            let scale = this.network.getScale()
            if (scale <= minZoom) {
                this.network.moveTo({
                    position: lastZoomPosition,
                    scale: minZoom
                });
            }
            else if (scale >= maxZoom) {
                this.network.moveTo({
                    position: lastZoomPosition,
                    scale: maxZoom
                });
            }
            else {
                lastZoomPosition = this.network.getViewPosition()
            }
        });

        this.network.on("dragEnd", () => {
            lastZoomPosition = this.network.getViewPosition()
        })
        await this.service.render();
    }

    public async onDetail(id: string) {
        const { code, data } = await wiz.call("detail", { id });
        this.data = data;
        await this.service.render();
    }

    public async updateNodeCount() {
        this.totalNodes = this.network.body.data.nodes.getIds().length;
        await this.service.render();
    }

    public async detailInfo() {
        this.detailShow = !this.detailShow;
        await this.service.render();
    }

    public async relInfo() {
        this.detailShow = false;
        await this.expandNode(this.data['id']);
        await this.service.render();
    }

    public async expandNode(id: string) {
        this.show.detail = false;
        const { code, data } = await wiz.call("expand", { id });
        let { nodes, edges } = data;

        this.totalNodes = nodes.length;
        let minZoom = 0.5;
        let maxZoom = 1.0;
        let lastZoomPosition = { x: 0, y: 0 };

        this.templates = [];
        nodes.forEach(node => {
            if (!this.templates.find(t => t.id === node.template_id)) {
                this.templates.push({ id: node.template_id, name: node.template_name, image: node.image, color: node.template_color, checked: true });
            }
        });

        nodes = nodes.map(node => {
            const hoverBackgroundColor = this.getHoverColor(node.color?.background, true);
            const hoverBorderColor = this.getHoverColor(node.color?.background, true);
            return {
                ...node,
                borderWidth: 8,
                size: 50,
                imagePadding: 20,
                color: {
                    ...node.color,
                    hover: {
                        background: hoverBackgroundColor,
                        border: hoverBorderColor,
                    },
                    highlight: {
                        background: hoverBackgroundColor,
                        border: hoverBorderColor,
                    }
                },
            };
        });

        let nodeDataSet = new DataSet(nodes);
        let edgeDataSet = new DataSet(edges);

        const options = {
            nodes: {
                size: 50,
                imagePadding: 20,
                borderWidth: 10
            },
            edges: {
                length: 180,
                font: {
                    align: "top",
                    color: "green"
                },
                smooth: {
                    type: 'dynamic'
                }
            },
            interaction: {
                dragNodes: true,
                hover: true,
                navigationButtons: true,
                zoomView: true,
                zoomSpeed: 0.5
            },
            physics: {
                enabled: true,
                barnesHut: {
                    theta: 0.5,
                    gravitationalConstant: -60000,
                    centralGravity: 0,
                    springLength: 95,
                    springConstant: 0.04,
                    damping: 0.05,
                    avoidOverlap: 0
                },
                maxVelocity: 10,
                minVelocity: 0.1,
                solver: 'barnesHut',
                timestep: 0.5
            }
        };


        await this.loading(true);
        this.network = new Network(this.canvas.nativeElement, { nodes: nodeDataSet, edges: edgeDataSet }, options);
        this.network.once("afterDrawing", async () => {
            await this.loading(false);
            await this.service.render();
        })

        this.network.on("click", async () => {
            this.show.detail = false;
            this.detailShow = false;
            await this.service.render();
        })
        this.network.on("selectNode", async (params) => {
            await this.onDetail(params.nodes[0]);
            let node = this.network.body.data.nodes.get(params.nodes[0]);
            this.isParent = node.isChild;
            await this.ref.detectChanges();
            this.x = (params.pointer.DOM.x + 50) + "px";
            this.y = params.pointer.DOM.y + "px";
            this.show.detail = true;
            this.service.render();
        });

        this.network.on("zoom", () => {
            let scale = this.network.getScale()
            if (scale <= minZoom) {
                this.network.moveTo({
                    position: lastZoomPosition,
                    scale: minZoom
                });
            }
            else if (scale >= maxZoom) {
                this.network.moveTo({
                    position: lastZoomPosition,
                    scale: maxZoom
                });
            }
            else {
                lastZoomPosition = this.network.getViewPosition()
            }
        });

        this.network.on("dragEnd", () => {
            lastZoomPosition = this.network.getViewPosition()
        })

        this.network.once("stabilize", async () => {
            await this.loading(false);
            await this.service.render();
        })
        await this.service.render();
    }

    public async filterNodes() {
        const selectedTemplates = this.templates.filter(t => t.checked).map(t => t.id);

        this.network.body.data.nodes.update(
            this.network.body.data.nodes.get().map(node => ({ id: node.id, hidden: true }))
        );

        this.network.body.data.nodes.update(
            this.network.body.data.nodes.get()
                .filter(node => !node.isChild || selectedTemplates.includes(node.template_id))
                .map(node => ({ id: node.id, hidden: false }))
        );

        this.network.body.data.edges.update(
            this.network.body.data.edges.get().map(edge => ({ id: edge.id, hidden: true }))
        );

        this.network.body.data.edges.update(
            this.network.body.data.edges.get()
                .filter(edge => !this.network.body.data.nodes.get(edge.from).hidden && !this.network.body.data.nodes.get(edge.to).hidden)
                .map(edge => ({ id: edge.id, hidden: false }))
        );

        await this.service.render();
    }

    public getHoverColor(color: string, lighter: boolean): string {
        const hoverColor = tinycolor(color);
        if (lighter) {
            return hoverColor.lighten(10).toString();
        } else {
            return hoverColor.darken(10).toString();
        }
    }
    public toggleTemplate(id: string) {
        let template = this.templates.find(t => t.id === id);
        if (template) {
            template.checked = !template.checked;
            this.filterNodes();
        }
    }

    public async loading(act) {
        this._loading = act;
        await this.service.render()
    }

}

export default ComponentViewerVisComponent;