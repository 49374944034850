import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.docs');
import { OnInit } from "@angular/core";
import { Service } from "src/libs/season/service";

@Component({
    selector: 'wiz-page-docs',
template: templateSource || '',
    styles: [`

/* file: /var/www/nuch/branch/main/build/src/app/page.docs/view.scss */
.workspace {
  width: 100%;
  height: 100%;
  display: flex;
}

.info-area {
  overflow: auto;
  width: 17%;
  height: 100%;
  padding: 16px;
}
.info-area > div {
  margin-bottom: 16px;
}
.info-area > div:last-child {
  margin-bottom: 0;
}
.info-area .featured-card {
  flex: auto;
}

.content-area {
  flex: 1;
  width: 83%;
  height: 100%;
  padding: 16px;
  padding-left: 0;
}
.content-area .card {
  display: block;
  height: 100%;
}
.content-area .card .action-area {
  position: relative;
  padding: 12px;
  text-align: center;
}
.content-area .card .action-area .btn {
  min-width: 120px;
}
.content-area .card .action-area .admin-menu {
  position: absolute;
  top: 16px;
  right: 24px;
}
.content-area .card .action-area .admin-menu .text-muted {
  cursor: pointer;
  margin: 16px;
}
.content-area .card .action-area .admin-menu .text-muted:hover {
  color: var(--wiz-color-red) !important;
}
.content-area .card .card-body {
  height: calc(100% - 72px);
  overflow: auto;
}
.content-area .card .card-end {
  height: 16px;
}

.h-100 {
  height: 100%;
}

.card {
  border-radius: 15px;
}

.container.ready {
  max-width: 640px;
}

.container-tight {
  max-width: 580px;
}

.empty-header {
  font-size: 48px;
}

.content-description {
  max-width: 720px;
}

table {
  width: 100%;
  table-layout: fixed;
}

th {
  background-color: rgba(0, 0, 0, 0.05) !important;
}
th.thead {
  width: 150px;
}

@media (max-width: 768px) {
  .workspace {
    display: block;
  }
  .workspace > div {
    width: 100%;
  }
  .info-area {
    height: auto;
    overflow: hidden;
  }
  .admin-menu {
    display: none;
  }
  .content-area {
    padding-left: 16px;
  }
}`],
})
export class PageDocsComponent implements OnInit {

    public mode: string = "graph"; // graph, doc, 3d, vr
    public target: string = "";
    public info: any = { obj3d: [] };
    public meta: any = {};
    public templates: any = [];
    public templatemap: any = {};

    constructor(@Inject( Service) public service: Service) {
        this.target = decodeURIComponent(WizRoute.segment.query);
        let hash = location.hash.split("#")[1];
        if (hash) this.mode = hash;
    }

    public async ngOnInit() {
        await this.service.init();
        await this.load();
    }

    public get3DUri() {
        let links = [];
        for (let i = 0; i < this.info.obj3d.length; i++)
            links.push("/res/3d/" + this.info.id + "/" + this.info.obj3d[i]);
        return links;
    }

    public findTemplate() {
        let template = this.templatemap[this.info.template_id];
        if (!template) return { attributes: [] };
        return template;
    }

    public async load() {
        let { code, data } = await wiz.call("info", { target: this.target })
        if (code == 200) {
            this.info = data.info;
            this.templates = data.templates;
            this.templatemap = {};
            for (let i = 0; i < this.templates.length; i++) {
                this.templatemap[this.templates[i].id] = this.templates[i];
            }

            this.meta = {};
            for (let i = 0; i < data.meta.length; i++) {
                this.meta[data.meta[i].namespace] = data.meta[i].value;
            }

            this.info.status = 200;
        } else {
            this.info = { status: 404 };
        }
        console.log(this.info);
        await this.service.render();
    }

    public isUrl(str) {
        const regex = /^(((http(s?))\:\/\/)?)([0-9a-zA-Z\-]+\.)+[a-zA-Z]{2,6}(\:[0-9]+)?(\/\S*)?/;
        return regex.test(str);
    }

    public isActive(menu: string) {
        return this.mode == menu ? 'btn-red' : 'btn-outline-red';
    }

    public async active(menu: string) {
        this.mode = menu;
        location.hash = "#" + menu;
        await this.service.render();
    }

    public async delete() {
        const res = await this.service.alert.show({ message: "정말 삭제하시겠습니까?" });
        if (!res) return;

        await wiz.call("delete", { target: this.target });
        await this.load();
        await this.service.render();
    }

    public async create() {
        location.href = "/edit/" + this.target;
    }

}

export default PageDocsComponent;