import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.bookmark');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/season/service";

@Component({
    selector: 'wiz-component-bookmark',
template: templateSource || '',
    styles: [`

/* file: /var/www/nuch/branch/main/build/src/app/component.bookmark/view.scss */
.card > .form-label {
  width: 120px;
}
.card > .form-content {
  flex: 1;
}
.card > .form-content .row + .row {
  margin-top: 10px;
}
.card h2.card-title {
  font-size: 19px;
  font-family: SUIT-Medium;
}
.card .header {
  display: flex;
  align-items: center;
}
.card .header h2 {
  margin: 0;
  padding: 0;
}
.card.category {
  width: 400px;
  background-color: white;
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.1607843137);
}
.card.category > * {
  padding: 20px 30px;
}
.card.category .info-wrap {
  height: auto;
  overflow: auto;
}
.card.category .info-wrap .info-label {
  font-family: "wiz-r";
  color: #7E84A3;
  font-size: 14px;
}
.card.category .info-wrap .info h2.card-title {
  font-size: 19px;
  font-family: SUIT-Medium;
}
.card.info-graph {
  flex: auto;
}
.card.info-graph > * {
  padding: 20px 30px;
}
.card.info-graph h2.card-title {
  font-size: 30px;
  font-family: SUIT-Medium;
}

.info-wrap {
  height: auto;
  overflow: auto;
}
.info-wrap .info-label {
  font-family: "wiz-r";
  color: #7E84A3;
  font-size: 14px;
}
.info-wrap .info h2.card-title {
  font-size: 19px;
  font-family: SUIT-Medium;
}
.info-wrap .info-value {
  font-family: "wiz-b";
  font-size: 16px;
}
.info-wrap .info hr {
  margin: 5px 0;
}
.info-wrap .info .card-gray {
  position: relative;
  background-color: #F2F2F3;
  border-radius: 5px;
  padding: 10px 20px;
}
.info-wrap .info .card-gray .btn-images {
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: 24px;
  height: 24px;
  background: transparent url("/assets/images/main_button_1.svg") 0% 0% no-repeat padding-box;
  opacity: 1;
  border: none;
}`],
})
export class ComponentBookmarkComponent implements OnInit {
    private bookmarks = [];
    private show = [];

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        this.onLoad();
    }

    private async onLoad() {
        const { code, data } = await wiz.call("load");
        if (code !== 200) {
            return;
        }
        this.bookmarks = data;
        this.show = data.map(_ => false);
        this.service.render();
    }

    private toggleRelation(i) {
        this.show[i] = !this.show[i];
        this.service.render();
    }
}

export default ComponentBookmarkComponent;