import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.auth.login');
import { OnInit } from '@angular/core';
import { Service } from "src/libs/season/service";
import toastr from 'toastr';

@Component({
    selector: 'wiz-page-auth-login',
template: templateSource || '',
    styles: [`

/* file: /var/www/nuch/branch/main/build/src/app/page.auth.login/view.scss */
.wrapper {
  background-image: url(/assets/images/login-bg.png);
  position: absolute;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.circle {
  background-color: #C9151D;
  width: 140px;
  height: 140px;
  top: -70px;
  left: 230px;
  border-radius: 70%;
  box-shadow: 0px 6px 9px rgba(0, 0, 0, 0.1607843137);
  z-index: 5;
  margin: 0 auto;
  position: absolute;
}

.form {
  padding: 20px 0;
  z-index: 2;
  color: #000000;
  background-color: white;
  border-radius: 10px;
  position: relative;
}
.form .id {
  border-radius: 10px 10px 0px 0px;
  border-bottom-color: #F2F2F3;
  font-family: "SUIT-Regular";
}
.form .pwd {
  border-radius: 0px 0px 10px 10px;
  border-top-color: #F2F2F3;
  font-family: "SUIT-Regular";
}

.logo {
  width: 70%;
  height: 70%;
  margin: auto;
}

.wrapper .form-success .container h1 {
  transform: translateY(85px);
}

.container {
  max-width: 600px;
  margin: 0 auto;
  padding: 10vh 0;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.box-login {
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #fff;
  box-sizing: border-box;
  width: 70%;
  margin: auto;
}

.form input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  border: 1px solid rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
  display: block;
  font-size: 18px;
  color: #2b2929;
  transition-duration: 0.25s;
  position: relative;
  width: 100%;
  margin: 0;
  padding: 18px 19px 19px;
  box-sizing: border-box;
}

.form input:hover {
  background-color: rgb(255, 255, 255);
}

.form input:focus {
  background-color: rgb(255, 255, 255);
  width: 100%;
  color: #000000;
}

.form button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  border: 1px solid rgb(0, 0, 0);
  background-color: rgb(0, 0, 0);
  border-radius: 10px;
  display: block;
  text-align: center;
  font-size: 18px;
  color: #ffffff;
  transition-duration: 0.25s;
  position: relative;
  width: 70%;
  margin: auto;
  margin-bottom: 100px;
  padding: 18px 19px 19px;
  box-sizing: border-box;
  font-family: "SUIT-Medium";
}

.form button:hover {
  background-color: #d1d2d3;
}`],
})
export class PageAuthLoginComponent implements OnInit {

    public username: string = '';
    public password: string = '';

    constructor(@Inject( Service)         public service: Service,    ) { }

    public async ngOnInit() {
        await this.service.init();
    }

    public async login() {
        const body = {
            id: this.username,
            password: this.password,
        }
        let { code, data } = await wiz.call("login", body);
        if (code === 200) {
            location.href = "/";
            return;
        }
        toastr.error(data);
    }
}

export default PageAuthLoginComponent;