import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.xml');
import { OnInit } from '@angular/core';

@Component({
    selector: 'wiz-component-xml',
template: templateSource || '',
    styles: [`

/* file: /var/www/nuch/branch/main/build/src/app/component.xml/view.scss */
.video-wrapper {
  width: 100%;
}

.video-js {
  width: 100%;
}

video {
  width: 100%;
  height: 360px !important;
}`],
})
export class ComponentXmlComponent implements OnInit {

    public async ngOnInit() {
        embedpano({ swf: "krpano.swf", xml: "assets/krpano-1.21/viewer/examples/depthmap/abu-simbel-tempel-tour/tour.xml", target: "pano" });
    }
}

export default ComponentXmlComponent;