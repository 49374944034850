import '@angular/compiler';
import { ComponentDocInfoComponent } from './component.doc.info/component.doc.info.component';
import { ComponentWikiComponent } from './component.wiki/component.wiki.component';
import { PageTestComponent } from './page.test/page.test.component';
import { ComponentUser3dViewerComponent } from './component.user.3d.viewer/component.user.3d.viewer.component';
import { PageMainComponent } from './page.main/page.main.component';
import { LayoutEmptyComponent } from './layout.empty/layout.empty.component';
import { ComponentNavAsideComponent } from './component.nav.aside/component.nav.aside.component';
import { ComponentDocEditorComponent } from './component.doc.editor/component.doc.editor.component';
import { ComponentXmlComponent } from './component.xml/component.xml.component';
import { ComponentPagenationComponent } from './component.pagenation/component.pagenation.component';
import { PageEditComponent } from './page.edit/page.edit.component';
import { ComponentNavNavbarComponent } from './component.nav.navbar/component.nav.navbar.component';
import { LayoutAdminComponent } from './layout.admin/layout.admin.component';
import { ComponentModalAlertComponent } from './component.modal.alert/component.modal.alert.component';
import { PageAdminTemplateComponent } from './page.admin.template/page.admin.template.component';
import { ComponentLoadingComponent } from './component.loading/component.loading.component';
import { PortalSeasonAlertComponent } from './portal.season.alert/portal.season.alert.component';
import { PageAuthLoginComponent } from './page.auth.login/page.auth.login.component';
import { Component3dViewerComponent } from './component.3d.viewer/component.3d.viewer.component';
import { ComponentDetailComponent } from './component.detail/component.detail.component';
import { ComponentAutocompleteComponent } from './component.autocomplete/component.autocomplete.component';
import { ComponentViewerVisComponent } from './component.viewer.vis/component.viewer.vis.component';
import { ComponentRelationComponent } from './component.relation/component.relation.component';
import { PageDocsComponent } from './page.docs/page.docs.component';
import { ComponentDocUpload3dComponent } from './component.doc.upload.3d/component.doc.upload.3d.component';
import { ComponentDocUploadVrComponent } from './component.doc.upload.vr/component.doc.upload.vr.component';
import { ComponentBookmarkComponent } from './component.bookmark/component.bookmark.component';
import { ComponentObjUploadComponent } from './component.obj.upload/component.obj.upload.component';
import { LayoutNavbarComponent } from './layout.navbar/layout.navbar.component';
import { PageTest2Component } from './page.test2/page.test2.component';
import { PageObjListComponent } from './page.obj.list/page.obj.list.component';
import { PortalSeasonLoadingHexaComponent } from './portal.season.loading.hexa/portal.season.loading.hexa.component';
import { ComponentImageComponent } from './component.image/component.image.component';
import { ComponentLoadingHexaComponent } from './component.loading.hexa/component.loading.hexa.component';
import { ComponentVrViewerComponent } from './component.vr.viewer/component.vr.viewer.component';

const INDEX_PAGE = "main";

import { URLPattern } from "urlpattern-polyfill";
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

let app_routes: Routes = [
    {
        component: LayoutEmptyComponent,
        "children": [
            {
                "path": "test",
                component: PageTestComponent,
                "app_id": "page.test"
            },
            {
                "path": "login",
                component: PageAuthLoginComponent,
                "app_id": "page.auth.login"
            },
            {
                "path": "test2",
                component: PageTest2Component,
                "app_id": "page.test2"
            }
        ]
    },
    {
        component: LayoutNavbarComponent,
        "children": [
            {
                "path": "main",
                component: PageMainComponent,
                "app_id": "page.main"
            },
            {
                "path": "edit/:query",
                component: PageEditComponent,
                "app_id": "page.edit"
            },
            {
                "path": "admin/template",
                component: PageAdminTemplateComponent,
                "app_id": "page.admin.template"
            },
            {
                "path": "doc/:query",
                component: PageDocsComponent,
                "app_id": "page.docs"
            },
            {
                "path": "obj/list",
                component: PageObjListComponent,
                "app_id": "page.obj.list"
            }
        ]
    }
];

export class RouteInfo {
    public path: any = "";
    public segment: any = {};

    constructor() { }
}

window.WizRoute = new RouteInfo();

let patternMatcher = (pattern: any, url: any) => {
    let urlpath = url.map((x: any) => x.path).join("/");
    let testurl = 'http://test/';
    pattern = '/' + pattern;
    urlpath = testurl + urlpath;
    pattern = new URLPattern({ pathname: pattern });
    pattern = pattern.exec(urlpath)
    if (pattern && pattern.pathname) {
        let posParams = {};
        for (let key in pattern.pathname.groups) {
            if (pattern.pathname.groups[key]) {
                posParams[key] = pattern.pathname.groups[key];
            }
        }
        window.WizRoute.path = url.map((x: any) => x.path).join("/");
        window.WizRoute.segment = posParams;

        return { consumed: url, posParams: posParams };
    }
    return null
}

let routes: Routes = [{
    matcher: (url: any) => {
        for (let i = 0; i < app_routes.length; i++) {
            let layout = app_routes[i];
            let layout_childs = layout.children;
            for (let j = 0; j < layout_childs.length; j++) {
                let child = layout_childs[j];
                let matcher = patternMatcher(child.path, url);
                if (matcher)
                    return null;
            }
        }
        return { consumed: url, posParams: {} };
    },
    redirectTo: INDEX_PAGE
}];

for (let i = 0; i < app_routes.length; i++) {
    let layout = app_routes[i];
    let layout_component = layout.component;
    let layout_childs = layout.children;

    let router: any = {
        path: '',
        component: layout_component,
        children: []
    };

    for (let j = 0; j < layout_childs.length; j++) {
        let child = layout_childs[j];
        router.children.push({
            matcher: (url: any) => {
                let matcher = patternMatcher(child.path, url);
                if (matcher) return matcher;
                return null;
            },
            component: child.component
        });
    }
    routes.push(router);
}

@NgModule({ imports: [RouterModule.forRoot(routes)], exports: [RouterModule] })
export class AppRoutingModule { }