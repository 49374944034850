import '@angular/compiler';
import { ComponentDocInfoComponent } from './component.doc.info/component.doc.info.component';
import { ComponentWikiComponent } from './component.wiki/component.wiki.component';
import { PageTestComponent } from './page.test/page.test.component';
import { ComponentUser3dViewerComponent } from './component.user.3d.viewer/component.user.3d.viewer.component';
import { PageMainComponent } from './page.main/page.main.component';
import { LayoutEmptyComponent } from './layout.empty/layout.empty.component';
import { ComponentNavAsideComponent } from './component.nav.aside/component.nav.aside.component';
import { ComponentDocEditorComponent } from './component.doc.editor/component.doc.editor.component';
import { ComponentXmlComponent } from './component.xml/component.xml.component';
import { ComponentPagenationComponent } from './component.pagenation/component.pagenation.component';
import { PageEditComponent } from './page.edit/page.edit.component';
import { ComponentNavNavbarComponent } from './component.nav.navbar/component.nav.navbar.component';
import { LayoutAdminComponent } from './layout.admin/layout.admin.component';
import { ComponentModalAlertComponent } from './component.modal.alert/component.modal.alert.component';
import { PageAdminTemplateComponent } from './page.admin.template/page.admin.template.component';
import { ComponentLoadingComponent } from './component.loading/component.loading.component';
import { PortalSeasonAlertComponent } from './portal.season.alert/portal.season.alert.component';
import { PageAuthLoginComponent } from './page.auth.login/page.auth.login.component';
import { Component3dViewerComponent } from './component.3d.viewer/component.3d.viewer.component';
import { ComponentDetailComponent } from './component.detail/component.detail.component';
import { ComponentAutocompleteComponent } from './component.autocomplete/component.autocomplete.component';
import { ComponentViewerVisComponent } from './component.viewer.vis/component.viewer.vis.component';
import { ComponentRelationComponent } from './component.relation/component.relation.component';
import { PageDocsComponent } from './page.docs/page.docs.component';
import { ComponentDocUpload3dComponent } from './component.doc.upload.3d/component.doc.upload.3d.component';
import { ComponentDocUploadVrComponent } from './component.doc.upload.vr/component.doc.upload.vr.component';
import { ComponentBookmarkComponent } from './component.bookmark/component.bookmark.component';
import { ComponentObjUploadComponent } from './component.obj.upload/component.obj.upload.component';
import { LayoutNavbarComponent } from './layout.navbar/layout.navbar.component';
import { PageTest2Component } from './page.test2/page.test2.component';
import { PageObjListComponent } from './page.obj.list/page.obj.list.component';
import { PortalSeasonLoadingHexaComponent } from './portal.season.loading.hexa/portal.season.loading.hexa.component';
import { ComponentImageComponent } from './component.image/component.image.component';
import { ComponentLoadingHexaComponent } from './component.loading.hexa/component.loading.hexa.component';
import { ComponentVrViewerComponent } from './component.vr.viewer/component.vr.viewer.component';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxLoadingModule, ngxLoadingAnimationTypes } from "src/libs/ngx-loading";
import { NuMonacoEditorModule } from '@ng-util/monaco-editor';
import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { SortablejsModule } from "src/libs/ngx-sortablejs";

@NgModule({
    declarations: [
        AppComponent,
        ComponentDocInfoComponent,
        ComponentWikiComponent,
        PageTestComponent,
        ComponentUser3dViewerComponent,
        PageMainComponent,
        LayoutEmptyComponent,
        ComponentNavAsideComponent,
        ComponentDocEditorComponent,
        ComponentXmlComponent,
        ComponentPagenationComponent,
        PageEditComponent,
        ComponentNavNavbarComponent,
        LayoutAdminComponent,
        ComponentModalAlertComponent,
        PageAdminTemplateComponent,
        ComponentLoadingComponent,
        PortalSeasonAlertComponent,
        PageAuthLoginComponent,
        Component3dViewerComponent,
        ComponentDetailComponent,
        ComponentAutocompleteComponent,
        ComponentViewerVisComponent,
        ComponentRelationComponent,
        PageDocsComponent,
        ComponentDocUpload3dComponent,
        ComponentDocUploadVrComponent,
        ComponentBookmarkComponent,
        ComponentObjUploadComponent,
        LayoutNavbarComponent,
        PageTest2Component,
        PageObjListComponent,
        PortalSeasonLoadingHexaComponent,
        ComponentImageComponent,
        ComponentLoadingHexaComponent,
        ComponentVrViewerComponent
    ],
    imports: [
        EditorModule,
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        NgbModule,
        NuMonacoEditorModule.forRoot(),
        SortablejsModule,
        NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.cubeGrid,
            backdropBackgroundColour: "rgba(0,0,0,0.1)",
            primaryColour: "#3843D0",
            secondaryColour: "#3843D0",
            tertiaryColour: "#3843D0",
        }),
        
    ],
    providers: [
        {provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js'},
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
