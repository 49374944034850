import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.doc.upload.3d');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/season/service";
import moment from 'moment';

@Component({
    selector: 'wiz-component-doc-upload-3d',
template: templateSource || '',
    styles: [`

/* file: /var/www/nuch/branch/main/build/src/app/component.doc.upload.3d/view.scss */
.file-list {
  max-height: 200px;
  overflow: auto;
}

.card-upload .w-1 {
  width: 8px;
  white-space: nowrap;
  text-align: center;
}
.card-upload .filename {
  word-break: break-all;
}
.card-upload .filename div {
  padding: 0;
}
.card-upload th {
  text-align: center;
}
.card-upload tr .d-flex .form-control {
  border-radius: 36px;
}
.card-upload tr .actions {
  font-size: 24px;
}
.card-upload tr .actions .dropdown {
  display: inline-block;
}
.card-upload tr .actions .dropdown ul {
  display: none;
}
.card-upload tr .actions .dropdown ul i {
  width: 20px;
  margin-right: 4px;
}
.card-upload tr .actions .dropdown,
.card-upload tr .actions .btn {
  opacity: 0;
  min-width: 48px;
  margin: 0;
}
.card-upload tr:hover .dropdown {
  display: inline-block;
}
.card-upload tr:hover .dropdown ul.show {
  display: block;
}
.card-upload tr:hover .dropdown,
.card-upload tr:hover .btn {
  opacity: 1;
}
.card-upload th,
.card-upload td {
  padding: 4px 12px;
  cursor: initial;
}
.card-upload th .icon-box,
.card-upload td .icon-box {
  display: inline-block;
}
.card-upload th a,
.card-upload td a {
  color: #2F374E;
  cursor: pointer;
}
.card-upload th .avatar-container,
.card-upload td .avatar-container {
  display: flex;
  align-items: center;
  height: 32px;
  width: 32px;
  border-radius: 4px;
  text-align: center;
  vertical-align: middle;
  background-color: var(--wiz-color-border);
  color: #666;
}
.card-upload th .avatar-container.required,
.card-upload td .avatar-container.required {
  background-color: #92CEFF;
  color: #51ACF6;
}
.card-upload th .avatar-container i,
.card-upload td .avatar-container i {
  width: 32px;
  text-align: center;
}`],
})
export class ComponentDocUpload3dComponent implements OnInit {
    @Input() data: any = {};

    public files: any = [];
    public loading: boolean = false;

    constructor(@Inject( Service) public service: Service) {
        this.target = decodeURIComponent(WizRoute.segment.query);
    }

    public async ngOnInit() {
        await this.service.init();
        await this.load();
    }

    public get3DUri() {
        let links = [];
        for (let i = 0; i < this.files.length; i++)
            links.push("/res/3d/" + this.data.id + "/" + this.files[i].name);
        return links;
    }

    public async load() {
        let { data } = await wiz.call("files", { id: this.data.id });
        this.files = data;
        await this.service.render();
    }

    public async select(item: any) {
        this.data.obj3d = item.name;
        await this.service.render();
    }

    public async upload() {
        let files = await this.service.file.select();

        this.loading = true;
        await this.service.render();

        let fd = new FormData();
        let filepath = [];
        for (let i = 0; i < files.length; i++) {
            if (!files[i].filepath) files[i].filepath = files[i].name;
            fd.append('file[]', files[i]);
            filepath.push(files[i].filepath);
        }
        fd.append("filepath", JSON.stringify(filepath));
        fd.append("id", this.data.id);

        let url = wiz.url('upload');
        await this.service.file.upload(url, fd);

        this.loading = false;
        await this.service.render();
        await this.load();
    }

    public file: any = ((scope, self: any = {}) => {
        self.data = [];

        self.load = async () => {
            let { data } = await wiz.call("files", { id: scope.ID });
            self.data = data;
            self.data.sort((a, b) => a.name.localeCompare(b.name));
            await this.service.render();
        }

        self.timer = (value: any) => {
            return moment(new Date(value * 1000)).format("YYYY-MM-DD HH:mm:ss");
        }

        self.size = (value: any) => {
            if (!value) return "0B";
            let kb = value / 1024;
            if (kb < 1) return value + "B";
            let mb = kb / 1024;
            if (mb < 1) return Math.round(kb * 100) / 100 + "KB";
            let gb = mb / 1024;
            if (gb < 1) return Math.round(mb * 100) / 100 + "MB";
            let tb = gb / 1024;
            if (tb < 1) return Math.round(gb * 100) / 100 + "GB";
            return Math.round(tb * 100) / 100 + "TB";
        }

        return self;
    })(this);

    public async deleteFile() {
        this.files = [];
        await this.service.render();
        await wiz.call('deleteFile', { id: this.data.id });
        await this.load();
    }

}

export default ComponentDocUpload3dComponent;