import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.obj.upload');
import { OnInit } from "@angular/core";
import { Service } from "src/libs/season/service";
import toastr from 'toastr';

toastr.options = {
    "closeButton": false,
    "debug": false,
    "newestOnTop": false,
    "progressBar": false,
    "positionClass": "toast-bottom-center",
    "preventDuplicates": false,
    "onclick": null,
    "showDuration": "3000",
    "hideDuration": "1000",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut",
};

@Component({
    selector: 'wiz-component-obj-upload',
template: templateSource || '',
    styles: [`

/* file: /var/www/nuch/branch/main/build/src/app/component.obj.upload/view.scss */
.workspace {
  width: 100%;
  height: 100%;
  display: flex;
}

.info-area {
  overflow: auto;
  width: 100%;
  height: 100%;
  padding: 16px;
}
.info-area > div {
  margin-bottom: 16px;
}
.info-area > div:last-child {
  margin-bottom: 0;
}
.info-area .info-card img {
  margin-top: 8px;
  margin-bottom: 16px;
}
.info-area .info-card .text-muted {
  max-height: 300px;
  overflow: auto;
}
.info-area .info-card .featured-image {
  position: relative;
}
.info-area .info-card .featured-image .featured-action {
  position: absolute;
  top: 16px;
  right: 8px;
}
.info-area .info-card .featured-image .featured-action .btn {
  width: 24px;
  height: 24px;
}
.info-area .featured-card {
  flex: auto;
}

.content-area {
  flex: 1;
  height: 100%;
  width: 85%;
  padding: 16px;
  padding-left: 0;
}
.content-area .card {
  display: block;
  height: 100%;
}
.content-area .card .action-area {
  position: relative;
  padding: 12px;
  text-align: center;
}
.content-area .card .action-area .btn {
  min-width: 120px;
}
.content-area .card .action-area .admin-menu {
  position: absolute;
  top: 16px;
  right: 24px;
}
.content-area .card .action-area .admin-menu .text-muted {
  cursor: pointer;
}
.content-area .card .action-area .admin-menu .text-muted:hover {
  color: var(--wiz-color-red) !important;
}
.content-area .card .card-body {
  height: calc(100% - 56px);
  overflow: auto;
}

.h-100 {
  height: 100%;
}

.card {
  border-radius: 15px;
}

.container.ready {
  max-width: 640px;
}

.container-tight {
  max-width: 580px;
}

.empty-header {
  font-size: 48px;
}

textarea.form-control {
  height: auto !important;
}

.action-btns {
  position: fixed;
  bottom: 32px;
  right: 32px;
}
.action-btns .btn {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  font-size: 24px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}

.content-info {
  overflow: hidden;
  max-width: 960px;
  margin: 0 auto;
  height: 100%;
}

.content-3d {
  max-width: 960px;
  margin: 0 auto;
}

.content-vr {
  max-width: 960px;
  margin: 0 auto;
}

table {
  width: 100%;
  table-layout: fixed;
}

th {
  background-color: rgba(0, 0, 0, 0.05) !important;
}
th.thead {
  width: 150px;
  border-right: 1px var(--wiz-color-red) solid;
}

.table-actions .btn {
  width: 32px;
}

#toast-container {
  padding: 20px, 15px, 20px, 15px;
  font-size: 2rem;
}
#toast-container .toast-bottom-center {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}`],
})
export class ComponentObjUploadComponent implements OnInit {
    public target: string = "";
    public mode: string = "doc"; // doc, meta, rel, 3d, vr
    public link: string = "from";
    public relSearchInput: boolean = true;

    public info: any = {};
    public meta: any = {};
    public templates: any = [];
    public templatemap: any = {};
    public rel: any = { 'from': [], 'to': [] };

    constructor(@Inject( Service) public service: Service) {
        this.target = decodeURIComponent(WizRoute.segment.query);
        let hash = location.hash.split("#")[1];
        if (hash) this.mode = hash;
    }

    public async ngOnInit() {
        await this.service.init();
        await this.service.auth.allow('admin', '/auth/login');
    }

    public async load() {
        let { code, data } = await wiz.call("info", { target: this.target });
        this.templates = data.templates;

        this.templatemap = {};
        for (let i = 0; i < this.templates.length; i++) {
            this.templatemap[this.templates[i].id] = this.templates[i];
        }

        this.meta = {};
        this.rel = { 'from': [], 'to': [] };

        if (code == 200) {
            this.info = data.info;
            this.info.status = 200;

            for (let i = 0; i < data.meta.length; i++) {
                this.meta[data.meta[i].namespace] = data.meta[i].value;
            }

            this.rel['from'] = data.rel['from'];
            this.rel['to'] = data.rel['to'];
        } else {
            this.info = {
                status: 200,
                bookmark: 0,
                class_id: '',
                description: '',
                name: "undefined",
                summary: ''
            };
        }
        await this.service.render();
    }


    public async update() {
        if (!this.info.template_id) {
            toastr.error("템플릿을 지정해주세요");
            return
        }
        else {
            let { data } = await wiz.call('update', {
                data: JSON.stringify(this.info),
                meta: JSON.stringify(this.meta),
                rel: JSON.stringify(this.rel)
            });
            toastr.success("저장되었습니다");
            this.info.id = data;
            await this.service.render();
        }
    }


    public searchQuery() {
        return async (text) => {
            const { code, data } = await wiz.call("search", { text });
            if (code !== 200) {
                return [];
            }
            const targetArray = this.rel[this.link].map(it => it.id);
            return data.filter(it => !targetArray.includes(it.id))
                .filter(it => it.id !== this.currentId);
        }
    }

    public async onSelectObject({ item }) {
        this.relSearchInput = false;
        await this.service.render();

        this.relSearchInput = true;
        await this.service.render();

        console.log(item);
        this.info.name = item.name; // 객체 이름 설정
        this.info.summary = item.summary;

        await this.service.render();
    }

}

export default ComponentObjUploadComponent;