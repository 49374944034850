import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('page.obj.list');
import { OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { Service } from "src/libs/season/service";
import toastr from 'toastr';

toastr.options = {
    "closeButton": false,
    "debug": false,
    "newestOnTop": false,
    "progressBar": false,
    "positionClass": "toast-bottom-center",
    "preventDuplicates": false,
    "onclick": null,
    "showDuration": "3000",
    "hideDuration": "1000",
    "timeOut": "5000",
    "extendedTimeOut": "1000",
    "showEasing": "swing",
    "hideEasing": "linear",
    "showMethod": "fadeIn",
    "hideMethod": "fadeOut",
};

@Component({
    selector: 'wiz-page-obj-list',
template: templateSource || '',
    styles: [`

/* file: /var/www/nuch/branch/main/build/src/app/page.obj.list/view.scss */
.searchbar {
  height: 34px;
  width: max-content;
  border: 1px solid #e6e7e9;
  border-radius: 4px;
  display: inline-block;
}
.searchbar input[type=text] {
  border: 0;
  box-shadow: none;
  padding-right: 30px;
}
.searchbar input[type=text]:focus {
  border: 1px solid #c9151e;
  box-shadow: 0 0 0 0.25rem rgba(201, 21, 30, 0.25);
}
.searchbar .position-absolute {
  top: 0;
  right: 6px;
}
.searchbar i.fa-solid.fa-magnifying-glass {
  line-height: 32px;
}

table tr.active > td {
  background-color: #ececec;
}
table td {
  line-height: 32px;
}
table td i.fa-solid {
  font-size: 20px;
  cursor: pointer;
}
table img {
  display: inline-block;
}

.workspace {
  width: 100%;
  height: 100%;
  display: flex;
}

.editor-area {
  flex: auto;
  height: 100%;
  padding: 12px;
}

.pagenation {
  width: 100%;
  display: flex;
  justify-content: center;
}

.btn {
  border-radius: 32px;
  width: 24px;
  height: 24px;
  margin: 0 3px;
}
.btn.disabled {
  opacity: 0.2;
}`],
})
export class PageObjListComponent implements OnInit {
    constructor(@Inject( Service) public service: Service,@Inject( Router)  public router: Router) { }

    public async ngOnInit() {
        await this.service.init();
        await this.load();
    }

    public Math = Math;
    public list: any = [];
    public search: any = {
        page: 1,
        text: '',
        order: "DESC",
        orderby: "updated",
    };
    public pagenation: any = {
        end: 1,
        start: 1,
        pages: [],
        total: 0,
    };

    public async load(page = 1, orderby = null) {
        this.search.page = page;
        if (orderby) {
            if (orderby === this.search.orderby) { // order toggle
                if (this.search.order === "DESC") this.search.order = "ASC";
                else this.search.order = "DESC";
            }
            else {
                this.search.orderby = orderby;
                this.search.order = "ASC";
            }
        }
        await this.service.render();
        const body = {
            page: this.search.page,
            text: this.search.text,
            order: this.search.order,
            orderby: this.search.orderby,
        };
        await this.service.loading.show();
        const { code, data } = await wiz.call("load", body);
        await this.service.loading.hide();
        if (code !== 200) {
            toastr.error("Failed load");
            this.load = [];
            await this.service.render();
            return;
        }

        const { rows, lastpage, total } = data;
        const startpage = Math.floor((page - 1) / 10) * 10 + 1;
        this.list = rows;
        this.pagenation.start = startpage;
        this.pagenation.end = lastpage;
        this.pagenation.total = total;
        this.pagenation.pages = [];
        for (let i = 0; i < 10; i++) {
            if (startpage + i > lastpage) break;
            this.pagenation.pages.push(startpage + i);
        }
        await this.service.render();
    }

    public goToEdit(name: string) {
        this.router.navigate(['/edit', name]);
    }
}

export default PageObjListComponent;