import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.nav.navbar');
import { Input, OnInit, OnChanges, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { Service } from "src/libs/season/service";
@Component({
    selector: 'wiz-component-nav-navbar',
template: templateSource || '',
    styles: [`

/* file: /var/www/nuch/branch/main/build/src/app/component.nav.navbar/view.scss */
header.navbar {
  min-height: auto;
  border-bottom: 1px solid var(--wiz-color-border);
  padding-top: 12px;
  padding-bottom: 12px;
  background-color: #C9151D;
}
header.navbar .container {
  display: block;
  max-width: 3008px;
}
header.navbar .top-menu {
  display: flex;
  align-items: center;
  justify-content: end;
  padding-bottom: 4px;
}
header.navbar .top-menu > * {
  margin-left: 12px;
  font-size: 14px;
  color: var(--wiz-color-secondary);
  line-height: 1;
}
header.navbar .top-menu .dropdown .dropdown-toggle {
  font-size: 13px;
  padding: 0 12px;
  height: 21px;
}
header.navbar .main-menu {
  display: flex;
}
header.navbar .main-menu .navbar-brand {
  padding: 0;
}
header.navbar .main-menu .navbar-brand img {
  height: 60px;
}
header.navbar .main-menu .navbar-menu {
  display: flex;
  flex-direction: column;
  height: auto;
  flex: auto;
  align-items: flex-end;
}
header.navbar .main-menu .navbar-menu .nav-item {
  font-size: 18px;
  padding: 0 6px;
  cursor: pointer;
}
header.navbar .main-menu .navbar-menu .nav-item .nav-link {
  padding: 6px 16px;
  font-family: "SUIT-Medium";
}
header.navbar .main-menu .navbar-menu .nav-item .nav-link .nav-link-title {
  color: #FFFFFF;
}
header.navbar .main-menu .navbar-menu .nav-item .nav-link-auth {
  padding: 6px 16px;
  font-family: "SUIT-Medium";
  background-color: black;
  border-radius: 10px;
  text-align: center;
}
header.navbar .main-menu .navbar-menu .nav-item .nav-link-auth .nav-link-title {
  color: #FFFFFF;
}
header.navbar .main-menu .navbar-menu .nav-item.active {
  margin-left: 6px;
  margin-right: 6px;
}
header.navbar .main-menu .navbar-menu .nav-item.active .nav-link {
  font-weight: 1000;
  font-family: "SUIT-Medium";
  background-color: #E6222A;
  border-radius: 10px;
}
header.navbar .main-menu .navbar-menu .nav-item.active .nav-link .nav-link-title {
  color: #FFFFFF;
}
header.navbar .main-menu .navbar-menu .nav-items-top {
  display: none;
}
header.navbar .bookmark-area {
  position: absolute;
  top: 84px;
  right: 0;
  transition: all 0.1s linear;
  border: 1px solid #d7d3d1;
  height: calc(100vh - 84px);
}
header.navbar .bookmark-area:not(.active) {
  display: none;
}
header.navbar .bookmark-area.active {
  width: 400px;
  z-index: 9999;
  border-radius: 0;
  padding: 1rem;
}
header.navbar .bookmark-area.active .bookmark-toggle {
  width: 50px;
  height: 50px;
}
header.navbar .bookmark-area .bookmark-toggle {
  position: absolute;
  top: 0;
  right: 0;
}
header.navbar .bookmark-area .bookmark-toggle:hover {
  background-color: rgba(215, 211, 209, 0.3);
}
@media (max-width: 768px) {
  header.navbar .main-menu {
    display: block;
  }
  header.navbar .main-menu .navbar-toggler {
    display: inline-block;
    margin-right: 12px;
  }
  header.navbar .main-menu .navbar-brand {
    display: inline-block;
  }
  header.navbar .main-menu .navbar-menu {
    display: block;
  }
  header.navbar .main-menu .navbar-menu .nav-items-top {
    display: block;
    border-top: 1px solid var(--wiz-color-border);
    margin-top: 12px;
    padding-top: 8px;
  }
}

.form-control {
  width: 240px;
  border: none;
}`],
})
export class ComponentNavNavbarComponent implements OnInit {

    public query: string = "";

    private show = {
        bookmark: false,
    };
    public isMenuCollapsed: boolean = true;

    constructor(@Inject( Service)         public service: Service,@Inject( Router)         public router: Router,    ) {
        //router.subscribe(() => this.show.bookmark = false;)
    }

    public async ngOnInit() {
        await this.service.init();

    }

    public isAdmin() {
        return /^\/admin\/?/.test(this.router.url);
    }

    public toggleBookmark() {
        this.show.bookmark = !this.show.bookmark;
        if (this.show.bookmark) {
            setTimeout(() => {
                document.querySelector(".bookmark-area").classList.add("overflow-y");
            }, 100);
        }
        else {
            document.querySelector(".bookmark-area").classList.remove("overflow-y");
        }
        this.service.render();
    }

    public search(query: string) {
        location.href = "/doc/" + query;
    }
}

export default ComponentNavNavbarComponent;