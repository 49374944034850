import templateSource from './app.component.html';
              import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz');
import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
template: templateSource || '',
    styles: [``],
})
export class AppComponent { }
