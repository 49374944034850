import { Inject } from '@angular/core';
import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.doc.upload.vr');
import { OnInit, Input } from '@angular/core';
import { Service } from "src/libs/season/service";

@Component({
    selector: 'wiz-component-doc-upload-vr',
template: templateSource || '',
    styles: [`

/* file: /var/www/nuch/branch/main/build/src/app/component.doc.upload.vr/view.scss */
/* src/app/component.doc.upload.vr/view.scss: no such file or directory */`],
})
export class ComponentDocUploadVrComponent implements OnInit {
    @Input() data: any = {};

    public loading: boolean = false;
    public exists: boolean = false;

    constructor(@Inject( Service) public service: Service) {
        this.target = decodeURIComponent(WizRoute.segment.query);
    }

    public async ngOnInit() {
        await this.service.init();
        await this.check();
    }

    public async check() {
        let { code } = await wiz.call("check", { id: this.data.id });
        this.exists = code == 200;
        await this.service.render();
    }

    public async deleteFile() {
        await wiz.call('deleteFile', { id: this.data.id });
        await this.check();
    }

    public async upload() {
        let files = await this.service.file.select({ accept: "video/mp4" });

        this.loading = true;
        await this.service.render();

        let fd = new FormData();
        let filepath = [];
        for (let i = 0; i < files.length; i++) {
            if (!files[i].filepath) files[i].filepath = files[i].name;
            fd.append('file[]', files[i]);
            filepath.push(files[i].filepath);
        }
        fd.append("filepath", JSON.stringify(filepath));
        fd.append("id", this.data.id);

        let url = wiz.url('upload');
        await this.service.file.upload(url, fd);

        this.loading = false;
        await this.service.render();
        await this.check();
    }
}

export default ComponentDocUploadVrComponent;